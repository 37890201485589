.img-holder {
  opacity: 1;
}

.lottie-holder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.hide {
  opacity: 0;
}

@use '@scss/index.scss' as config;

.login-wrapper {
  margin: auto;
  text-align: center;
  padding-top: config.getSpacer('lg');
  padding-bottom: config.getSpacer('lg');
  max-width: 500px;
  position: relative;
}

.login-title {
  font-family: config.getTypo('h600', 'family');
  font-weight: config.getTypo('h600', 'weight');
  font-size: config.getTypo('h600', 'size');
  color: config.getColour('primary50');
  padding-bottom: config.getSpacer('sm');
  margin-top: config.getSpacer('sm');
}

.lottie-wrapper {

  & svg {
    width: 150px !important;
    height: 150px !important;
  }
}

.loading-text {
  font-family: config.getTypo('h400', 'family');
  font-weight: config.getTypo('h400', 'weight');
  font-size: config.getTypo('h400', 'size');
  color: config.getColour('grey0');
}
